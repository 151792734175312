import { AdminBuild, TagTypes } from './createApi';
import {
  Campaign,
  PaginatedQueryParams,
  PaginatedResult,
  CampaignTexts,
  CampaignImage,
  UploadFileResponse,
  Grant,
  InstallmentConfig,
  CommunityConfig,
  ProgramApplication,
  ProgramApplicationDetail,
  ProgramWithApplicationsToReview,
  SearchInputParams,
} from './types';
import { GrantsImportJob } from './types/ImportJob';
import type { StartImportResponse } from './types/ImportJob';

const getCampaigns = (build: AdminBuild) =>
  build.query<PaginatedResult<Campaign>, PaginatedQueryParams>({
    providesTags: [TagTypes.Campaign],
    query: (pagination) => ({
      url: `/partnership/campaigns`,
      method: 'GET',
      params: {
        ...pagination,
      },
    }),
  });

const searchCampaignByName = (build: AdminBuild) =>
  build.query<Campaign[], SearchInputParams>({
    query: ({ search, limit }) => ({
      url: '/partnership/campaigns/searchCampaign',
      method: 'GET',
      params: {
        search,
        limit,
      },
    }),
    transformResponse: (response: { campaigns: Campaign[] }) => response.campaigns,
  });

type CampaignImagesSearchQueryFilter = PaginatedQueryParams & {
  campaignId: string;
};

const getCampaignImages = (build: AdminBuild) =>
  build.query<PaginatedResult<CampaignImage>, CampaignImagesSearchQueryFilter>({
    providesTags: [TagTypes.CampaignImage],
    query: ({ campaignId, ...pagination }) => ({
      url: `/partnership/campaigns/${campaignId}/images`,
      method: 'GET',
      params: {
        ...pagination,
      },
    }),
  });

type CampaignSearchQueryFilter = PaginatedQueryParams & {
  partnerId: string;
};

const getCampaignsByPartner = (build: AdminBuild) =>
  build.query<PaginatedResult<Campaign>, CampaignSearchQueryFilter>({
    providesTags: [TagTypes.Campaign],
    query: ({ partnerId, ...pagination }) => ({
      url: `/partnership/campaigns/byPartner`,
      method: 'GET',
      params: {
        partnerId,
        ...pagination,
      },
    }),
  });

const getCampaign = (build: AdminBuild) =>
  build.query<{ campaign: Campaign }, { campaignId: string }>({
    providesTags: [TagTypes.Campaign],
    query: ({ campaignId }) => `/partnership/campaigns/${campaignId}`,
  });

const uploadCampaignImage = (build: AdminBuild) =>
  build.mutation<
    UploadFileResponse,
    { campaignId: string; key: string; alt: string; originalFileName: string; contentType: string }
  >({
    query: ({ campaignId, key, alt, originalFileName, contentType }) => ({
      url: `/partnership/campaigns/${campaignId}/images`,
      method: 'POST',
      body: {
        contentType,
        key,
        alt,
        originalFileName,
      },
    }),
    invalidatesTags: [TagTypes.CampaignImage, TagTypes.CampaignPredefinedKeys],
  });

const getCampaignPredefinedKeys = (build: AdminBuild) =>
  build.query<Array<string>, { campaignId: string }>({
    providesTags: [TagTypes.CampaignPredefinedKeys],
    query: ({ campaignId }) => ({
      url: `/partnership/campaigns/${campaignId}/images/predefinedKeys`,
      method: 'GET',
    }),
    transformResponse: (response: { data: string[] }) => response.data,
  });

type CreateCampaignParams = Omit<
  Campaign,
  | 'PK'
  | 'createdAt'
  | 'updatedAt'
  | 'pendingGrantsCount'
  | 'activeGrantsCount'
  | 'inactiveGrantsCount'
  | 'createdGrantsCount'
  | 'appliedGrantsCount'
  | 'rejectedGrantsCount'
  | 'settleRejectedGrantsCount'
  | 'settleAcceptedGrantsCount'
  | 'amount'
  | 'campaignId'
  | 'partnerName'
  | 'communityConfig'
> & {
  communityConfig?: Omit<CommunityConfig, 'status'>;
};
const createCampaign = (build: AdminBuild) =>
  build.mutation<void, CreateCampaignParams>({
    query: ({
      partnerId,
      name,
      code,
      stackwellFee,
      hasEndDate,
      activeUntil,
      installmentsConfig,
      hasCommunity,
      communityConfig,
    }) => ({
      url: `/partnership/campaigns`,
      method: 'POST',
      body: {
        partnerId,
        name,
        code,
        stackwellFee,
        hasEndDate,
        activeUntil,
        installmentsConfig,
        hasCommunity,
        communityConfig,
      },
    }),
    invalidatesTags: [TagTypes.Campaign],
  });

const startCommunityProgram = (build: AdminBuild) =>
  build.mutation<void, { campaignId: string }>({
    query: ({ campaignId }) => ({
      url: `/partnership/campaigns/${campaignId}/program/start`,
      method: 'PUT',
    }),
    invalidatesTags: [TagTypes.Campaign],
  });

const pauseCommunityProgram = (build: AdminBuild) =>
  build.mutation<void, { campaignId: string }>({
    query: ({ campaignId }) => ({
      url: `/partnership/campaigns/${campaignId}/program/pause`,
      method: 'PUT',
    }),
    invalidatesTags: [TagTypes.Campaign],
  });

const finishCommunityProgram = (build: AdminBuild) =>
  build.mutation<void, { campaignId: string }>({
    query: ({ campaignId }) => ({
      url: `/partnership/campaigns/${campaignId}/program/finish`,
      method: 'PUT',
    }),
    invalidatesTags: [TagTypes.Campaign],
  });

type CampaignTextsPK = {
  campaignId: string;
};

type UpdateCampaignTextsParams = CampaignTextsPK & {
  texts: CampaignTexts;
};
const updateCampaignTexts = (build: AdminBuild) =>
  build.mutation<void, UpdateCampaignTextsParams>({
    query: ({ campaignId, texts }) => ({
      url: `/partnership/campaigns/${campaignId}/texts`,
      method: 'PUT',
      body: texts,
    }),
    invalidatesTags: [TagTypes.CampaignTexts],
  });

type CampaignTextsResponse = {
  texts: CampaignTexts;
};
const getCampaignTexts = (build: AdminBuild) =>
  build.query<CampaignTextsResponse, CampaignTextsPK>({
    providesTags: [TagTypes.CampaignTexts],
    query: ({ campaignId }) => ({
      url: `/partnership/campaigns/${campaignId}/texts`,
    }),
  });

const deleteCampaignImage = (build: AdminBuild) =>
  build.mutation<UploadFileResponse, { campaignId: string; key: string }>({
    query: ({ campaignId, key }) => ({
      url: `/partnership/campaigns/${campaignId}/images`,
      method: 'DELETE',
      body: {
        key,
      },
    }),
    invalidatesTags: [TagTypes.CampaignImage, TagTypes.CampaignPredefinedKeys],
  });

type CampaignGrantsImportJobsFilter = PaginatedQueryParams & {
  campaignId: string;
};
const getCampaignGrantsImportJobs = (build: AdminBuild) =>
  build.query<PaginatedResult<GrantsImportJob>, CampaignGrantsImportJobsFilter>({
    query: ({ campaignId, ...pagination }) => ({
      url: `/partnership/campaigns/${campaignId}/grants/import`, //PartnerName not needed
      method: 'GET',
      params: {
        ...pagination,
      },
    }),
  });

type StartGrantsImportResponse = StartImportResponse & {
  job: GrantsImportJob;
};

// TODO maybe move to grants controller.
const startGrantsImport = (build: AdminBuild) =>
  build.mutation<StartGrantsImportResponse, { campaignId: string; originalFileName: string }>({
    query: ({ campaignId, originalFileName }) => ({
      url: `/partnership/campaigns/${campaignId}/grants/import`,
      method: 'POST',
      body: { originalFileName },
    }),
  });

type GrantSearchQueryFilter = PaginatedQueryParams & {
  campaignId: string;
  email: string;
};
const getCampaignGrants = (build: AdminBuild) =>
  build.query<PaginatedResult<Grant>, GrantSearchQueryFilter>({
    providesTags: [TagTypes.Grants],
    query: ({ campaignId, email, ...pagination }) => ({
      url: `/partnership/campaigns/${campaignId}/grants`,
      method: 'GET',
      params: {
        email,
        ...pagination,
      },
    }),
  });

const updateCampaign = (build: AdminBuild) =>
  build.mutation<
    void,
    {
      campaignId: string;
      name: string;
      code: string;
      installmentsConfig: InstallmentConfig[];
      hasCommunity: boolean;
      communityConfig?: Omit<CommunityConfig, 'status'>;
    }
  >({
    query: ({ campaignId, name, code, installmentsConfig, hasCommunity, communityConfig }) => ({
      url: `/partnership/campaigns/${campaignId}`,
      method: 'PUT',
      body: {
        name,
        code,
        installmentsConfig,
        hasCommunity,
        communityConfig,
      },
    }),
    invalidatesTags: [TagTypes.Campaign],
  });

type TypeformValidtionResponse = {
  isValid: boolean;
  error: string;
};

const getFormDefinition = (build: AdminBuild) =>
  build.query<TypeformValidtionResponse, { formId: string }>({
    query: ({ formId }) => ({
      url: `/partnership/campaigns/form/${formId}`,
      method: 'GET',
    }),
    transformResponse: (response: { data: { isValid: boolean; error: string } }) => response.data,
  });

type ApplicationProgramSearchQueryFilter = PaginatedQueryParams & {
  campaignId: string;
  status: string;
};

const getProgramApplications = (build: AdminBuild) =>
  build.query<PaginatedResult<ProgramApplication>, ApplicationProgramSearchQueryFilter>({
    providesTags: [TagTypes.ProgramApplications, TagTypes.Grants],
    query: ({ campaignId, status, ...pagination }) => ({
      url: `/partnership/campaigns/${campaignId}/programs-applications`,
      method: 'GET',
      params: {
        status,
        campaignId,
        ...pagination,
      },
    }),
  });

const getProgramsWithApplicationsToReview = (build: AdminBuild) =>
  build.query<{ programs: ProgramWithApplicationsToReview[] }, void>({
    providesTags: [TagTypes.ProgramApplications, TagTypes.Campaign, TagTypes.Grants],
    query: () => ({
      url: `/partnership/campaigns/program-applications-to-review`,
      method: 'GET',
    }),
  });

const getProgramApplication = (build: AdminBuild) =>
  build.query<{ programApplication: ProgramApplicationDetail }, { grantId: string }>({
    providesTags: [TagTypes.ProgramApplications],
    query: ({ grantId }) => ({
      url: `/partnership/grants/${encodeURIComponent(grantId)}/program-application`,
      method: 'GET',
    }),
  });

const acceptProgramApplication = (build: AdminBuild) =>
  build.mutation<void, { grantId: string }>({
    query: ({ grantId }) => ({
      url: `/partnership/grants/${encodeURIComponent(grantId)}/program-application/accept`,
      method: 'POST',
    }),
    invalidatesTags: [TagTypes.ProgramApplications],
  });

const rejectProgramApplication = (build: AdminBuild) =>
  build.mutation<void, { grantId: string }>({
    query: ({ grantId }) => ({
      url: `/partnership/grants/${encodeURIComponent(grantId)}/program-application/reject`,
      method: 'POST',
    }),
    invalidatesTags: [TagTypes.ProgramApplications],
  });

const programApplicationToApplied = (build: AdminBuild) =>
  build.mutation<void, { grantId: string }>({
    query: ({ grantId }) => ({
      url: `/partnership/grants/${encodeURIComponent(grantId)}/program-application/to-applied`,
      method: 'POST',
    }),
    invalidatesTags: [TagTypes.ProgramApplications],
  });

export {
  createCampaign,
  getCampaign,
  getCampaigns,
  searchCampaignByName,
  getCampaignPredefinedKeys,
  getCampaignsByPartner,
  getCampaignImages,
  uploadCampaignImage,
  updateCampaignTexts,
  getCampaignTexts,
  deleteCampaignImage,
  getCampaignGrantsImportJobs,
  startGrantsImport,
  getCampaignGrants,
  startCommunityProgram,
  pauseCommunityProgram,
  finishCommunityProgram,
  updateCampaign,
  getFormDefinition,
  getProgramApplication,
  getProgramApplications,
  acceptProgramApplication,
  rejectProgramApplication,
  programApplicationToApplied,
  getProgramsWithApplicationsToReview,
};
