import { AdminBuild, TagTypes } from './createApi';
import {
  Community,
  PaginatedQueryParams,
  PaginatedResult,
  SearchInputParams,
  CommunityImage,
  CommunityProgram,
  CommunityEligibleEmail,
  UploadFileResponse,
  CommunityTexts,
  CommunityMainPartner,
  CommunityDeepLink,
} from './types';
import { StartImportResponse, GrantsImportJob } from './types/ImportJob';

type CommunityId = {
  communityId: string;
};

export const searchCommunityByName = (build: AdminBuild) =>
  build.query<Community[], SearchInputParams>({
    query: ({ search, limit }) => ({
      url: '/partnership/communities/searchCommunity',
      method: 'GET',
      params: {
        search,
        limit,
      },
    }),
    transformResponse: (response: { communities: Community[] }) => response.communities,
  });

export const getCommunities = (build: AdminBuild) =>
  build.query<PaginatedResult<Community>, PaginatedQueryParams>({
    providesTags: [TagTypes.Community],
    query: (pagination) => ({
      url: '/partnership/communities',
      method: 'GET',
      params: {
        ...pagination,
      },
    }),
  });

export type CommunityByPartnerQueryFilter = PaginatedQueryParams & {
  partnerId: string;
};

export const getCommunitiesByPartner = (build: AdminBuild) =>
  build.query<PaginatedResult<Community>, CommunityByPartnerQueryFilter>({
    providesTags: [TagTypes.Community],
    query: ({ partnerId, ...pagination }) => ({
      url: '/partnership/communities/byPartner',
      method: 'GET',
      params: {
        partnerId,
        ...pagination,
      },
    }),
  });

export const getCommunitiesPredefinedKeys = (build: AdminBuild) =>
  build.query<Array<string>, string>({
    providesTags: [TagTypes.CommunityPredefinedKeys],
    query: (id) => ({
      url: `/partnership/communities/${id}/images/predefinedKeys`,
      method: 'GET',
    }),
    transformResponse: (response: { data: string[] }) => response.data,
  });

type CommunityImagesSearchQueryFilter = PaginatedQueryParams & {
  id: string;
};

export const getCommunityImages = (build: AdminBuild) =>
  build.query<PaginatedResult<CommunityImage>, CommunityImagesSearchQueryFilter>({
    providesTags: [TagTypes.CommunityImage],
    query: ({ id, ...pagination }) => ({
      url: `/partnership/communities/${id}/images`,
      method: 'GET',
      params: {
        ...pagination,
      },
    }),
  });

type CommunityResponse = {
  community: Community;
  communityDeepLink?: CommunityDeepLink;
  programs: CommunityProgram[];
};

export const getCommunity = (build: AdminBuild) =>
  build.query<CommunityResponse, string>({
    providesTags: [TagTypes.Community],
    query: (id) => `/partnership/communities/${id}`,
  });

type CommunityEligibleEmailsSearchQueryFilter = PaginatedQueryParams & {
  id: string;
  email: string;
};
export const getCommunityEligibleEmails = (build: AdminBuild) =>
  build.query<PaginatedResult<CommunityEligibleEmail>, CommunityEligibleEmailsSearchQueryFilter>({
    providesTags: [TagTypes.CommunityEligibleEmail],
    query: ({ id, email, ...pagination }) => ({
      url: `/partnership/communities/${id}/eligible-emails`,
      method: 'GET',
      params: {
        email,
        ...pagination,
      },
    }),
  });

type StartCommunityEligibleEmailsImportResponse = StartImportResponse & {
  job: GrantsImportJob;
};
export const startCommunityEligibleEmailsImport = (build: AdminBuild) =>
  build.mutation<
    StartCommunityEligibleEmailsImportResponse,
    { id: string; originalFileName: string }
  >({
    query: ({ id, originalFileName }) => ({
      url: `/partnership/communities/${id}/emails/import`,
      method: 'POST',
      body: { originalFileName },
    }),
  });

export const uploadCommunityImage = (build: AdminBuild) =>
  build.mutation<
    UploadFileResponse,
    { id: string; key: string; alt: string; contentType: string; originalFileName: string }
  >({
    query: ({ id, key, alt, contentType, originalFileName }) => ({
      url: `/partnership/communities/${id}/image/add`,
      method: 'POST',
      body: {
        key,
        alt,
        contentType,
        originalFileName,
      },
    }),
    invalidatesTags: [TagTypes.CommunityImage, TagTypes.CommunityPredefinedKeys],
  });

export const deleteCommunityImage = (build: AdminBuild) =>
  build.mutation<UploadFileResponse, { id: string; key: string }>({
    query: ({ id, key }) => ({
      url: `/partnership/communities/${id}/image/remove`,
      method: 'DELETE',
      body: {
        key,
      },
    }),
    invalidatesTags: [TagTypes.CommunityImage, TagTypes.CommunityPredefinedKeys],
  });

type CommunityEligibleEmailsImportJobsFilter = PaginatedQueryParams & {
  id: string;
};
export const getCommunityEligibleEmailsImportJobs = (build: AdminBuild) =>
  build.query<PaginatedResult<GrantsImportJob>, CommunityEligibleEmailsImportJobsFilter>({
    query: ({ id, ...pagination }) => ({
      url: `/partnership/communities/${id}/emails/import`,
      method: 'GET',
      params: {
        ...pagination,
      },
    }),
  });

export const deleteCommunityEligibleEmail = (build: AdminBuild) =>
  build.mutation<void, { id: string; email: string }>({
    query: ({ id, email }) => ({
      url: `/partnership/communities/${id}/eligible-emails/${encodeURIComponent(email)}`,
      method: 'DELETE',
    }),
    invalidatesTags: [TagTypes.CommunityEligibleEmail],
  });

type CreateCommunityParams = {
  name: string;
  slug: string;
  mainPartner: CommunityMainPartner;
  isFeePaidByPartner: boolean;
  showInHome: boolean;
  communityCode?: string;
};
export const createCommunity = (build: AdminBuild) =>
  build.mutation<void, CreateCommunityParams>({
    query: (params) => ({
      url: `/partnership/communities`,
      method: 'POST',
      body: params,
    }),
    invalidatesTags: [TagTypes.Community],
  });

export type UpdateCommunityParams = CommunityId & {
  name: string;
  slug: string;
  isFeePaidByPartner?: boolean;
  showInHome: boolean;
  communityCode?: string;
};
export const updateCommunity = (build: AdminBuild) =>
  build.mutation<void, UpdateCommunityParams>({
    query: ({ communityId, name, slug, isFeePaidByPartner, showInHome, communityCode }) => ({
      url: `/partnership/communities/${communityId}`,
      method: 'PUT',
      body: { name, slug, isFeePaidByPartner, showInHome, communityCode },
    }),
    invalidatesTags: [TagTypes.Community],
  });

export const deleteCommunity = (build: AdminBuild) =>
  build.mutation<void, string>({
    query: (id) => ({
      url: `/partnership/communities/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: [TagTypes.Community],
  });

type UpdateCommunityTextsParams = CommunityId & {
  texts: CommunityTexts;
};

export const updateCommunityTexts = (build: AdminBuild) =>
  build.mutation<void, UpdateCommunityTextsParams>({
    query: ({ communityId, texts }) => ({
      url: `/partnership/communities/${communityId}/texts`,
      method: 'PUT',
      body: texts,
    }),
    invalidatesTags: [TagTypes.CommunityTexts],
  });

type CommunityTextsResponse = {
  texts: CommunityTexts;
};
export const getCommunityTexts = (build: AdminBuild) =>
  build.query<CommunityTextsResponse, CommunityId>({
    providesTags: [TagTypes.CommunityTexts],
    query: ({ communityId }) => ({
      url: `/partnership/communities/${communityId}/texts`,
    }),
  });

type AddCommunityMemberParams = CommunityId & {
  email: string;
};

export const AddCommunityMember = (build: AdminBuild) =>
  build.mutation<void, AddCommunityMemberParams>({
    query: ({ communityId, email }) => ({
      url: `/partnership/communities/${communityId}/eligible-emails`,
      method: 'PUT',
      body: {
        email,
      },
    }),
    invalidatesTags: [TagTypes.CommunityEligibleEmail],
  });
